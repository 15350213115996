import { isValidAddressForNetwork } from '@chainflip/bitcoin';
import { isValidSolanaAddress } from '@chainflip/solana';
import { CHAINFLIP_SS58_PREFIX } from '@chainflip/utils/consts';
import * as ss58 from '@chainflip/utils/ss58';
import { isHex } from '@chainflip/utils/string';
import { ethers } from 'ethers';
import {
  ethereum,
  bitcoin,
  polkadot,
  polygon,
  arbitrum,
  optimism,
  avalanche,
  moonbeam,
  filecoin,
  bsc,
  fantom,
  celo,
  solana,
} from './mainnet';
import {
  sepolia,
  bitcoinTestnet,
  dotTestnet,
  polygonMumbai,
  arbitrumSepolia,
  optimismSepolia,
  avalancheFuji,
  moonbaseAlpha,
  filecoinHyperspace,
  bscTestnet,
  fantomTestnet,
  celoAlfajores,
  backspinEthereum,
  backspinArbitrum,
  solanaDevnet,
  backspinSolana,
} from './testnet';
import { type ConfigChainId, type SupportedChainId } from './index';

export type AddressValidator = (address: string) => boolean;

export const validatePolkadotAddress: AddressValidator = (address) => {
  try {
    if (isHex(address)) {
      ss58.encode({ data: address, ss58Format: CHAINFLIP_SS58_PREFIX });
    } else {
      ss58.decode(address);
    }
    return true;
  } catch {
    return false;
  }
};

export const validateEvmAddress: AddressValidator = (address) => ethers.isAddress(address);

type BitcoinNetwork = 'mainnet' | 'testnet' | 'regtest';

const validateBitcoinAddress = (address: string, network: BitcoinNetwork) =>
  isValidAddressForNetwork(address, network);

export const validateBitcoinMainnetAddress: AddressValidator = (address: string) =>
  validateBitcoinAddress(address, 'mainnet');

export const validateBitcoinTestnetAddress: AddressValidator = (address: string) =>
  validateBitcoinAddress(address, 'testnet');

export const validateBitcoinRegtestAddress: AddressValidator = (address: string) =>
  validateBitcoinAddress(address, 'regtest');

export const validateChainAddress = (address: string): Record<SupportedChainId, boolean> => {
  const isValidEvmAddress = validateEvmAddress(address);
  const isValidPolkadotAddress = validatePolkadotAddress(address);

  return {
    [ethereum.id]: isValidEvmAddress,
    [bitcoin.id]: validateBitcoinMainnetAddress(address),
    [solana.id]: isValidSolanaAddress(address),
    [polkadot.id]: isValidPolkadotAddress,
    [polygon.id]: isValidEvmAddress,
    [arbitrum.id]: isValidEvmAddress,
    [optimism.id]: isValidEvmAddress,
    [avalanche.id]: isValidEvmAddress,
    [moonbeam.id]: isValidEvmAddress,
    [filecoin.id]: isValidEvmAddress,
    [bsc.id]: isValidEvmAddress,
    [fantom.id]: isValidEvmAddress,
    [celo.id]: isValidEvmAddress,
    // // testnet
    [sepolia.id]: isValidEvmAddress,
    [backspinEthereum.id]: isValidEvmAddress,
    [backspinArbitrum.id]: isValidEvmAddress,
    [bitcoinTestnet.id]:
      validateBitcoinTestnetAddress(address) || validateBitcoinRegtestAddress(address),
    [solanaDevnet.id]: isValidSolanaAddress(address),
    [backspinSolana.id]: isValidSolanaAddress(address),
    [dotTestnet.id]: isValidPolkadotAddress,
    [polygonMumbai.id]: isValidEvmAddress,
    [arbitrumSepolia.id]: isValidEvmAddress,
    [optimismSepolia.id]: isValidEvmAddress,
    [avalancheFuji.id]: isValidEvmAddress,
    [moonbaseAlpha.id]: isValidEvmAddress,
    [filecoinHyperspace.id]: isValidEvmAddress,
    [bscTestnet.id]: isValidEvmAddress,
    [fantomTestnet.id]: isValidEvmAddress,
    [celoAlfajores.id]: isValidEvmAddress,
  } satisfies Record<ConfigChainId, boolean>;
};
